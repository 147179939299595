/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// polyfills
// import "core-js/fn/array/find"
// import "core-js/fn/array/from"
// import "core-js/fn/map"
// import "core-js/fn/object/assign"
// import "core-js/fn/promise"
// import "core-js/fn/set"
// import "core-js/fn/weak-map"
// import "element-closest"
// import "mutation-observer-inner-html-shim"
import "core-js/stable";
import "regenerator-runtime/runtime";

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /\.js|\.jsx$/)
application.load(definitionsFromContext(context))
