// https://github.com/adrienpoly/stimulus-flatpickr

// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// you can also import a translation file
import { French } from "flatpickr/dist/l10n/fr.js";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/material_green.css"

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      altFormat: "j F Y H:i",
      altInput: true,
      dateFormat: "Y-m-d H:i",
      enableTime: true,
      locale: French,
      minuteIncrement: 15,
      time_24hr: true
    };
  }

  close(selectedDates, dateStr, instance) {
    var parent = $(this.element).parents('.period-selector')[0];
    if (selectedDates.length > 0 && parent) {
      if ($(this.element).hasClass('start_date')) {
        this.updateLimit(selectedDates, parent, 'end_date', 'minDate')
      // } else if ($(this.element).hasClass('end_date')) {
      //  this.updateLimit(selectedDates, parent, 'start_date', 'maxDate')
      };
    };
  }

  updateLimit(selectedDates, parent, klass, option) {
    var element = $(parent).find(`.${klass}.flatpickr-input[type*='hidden']`)[0];
    var picker = this.application.getControllerForElementAndIdentifier(element, 'flatpickr');
    picker.fp.set(option, selectedDates[0]);
  }
}
