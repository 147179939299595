import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "priceExcludingTax", "taxPrice", "priceWithTax", "discountPrice",
                     "nbMission", "discount", "discountForTotal", "vatRate",
                     "displayVatRate"]

  connect() {
    let lastInput = $('.price_with_tax');
    if (lastInput.length) {
      this.initializePrices();
    }
    else {
      console.error('the lastInput element is not yet in DOM');
    }
    this.evalNoDiscount();
  }

  initializePrices() {
    this.nbMissionTarget.value = $('#current_mission .quotation_mission').length;
    this.computePrices();
    this.displayVatRateTarget.textContent = this.displayPercent(100*parseFloat(this.vatRateTarget.value.replace(',', '.')))

    $('.ui.checkbox').checkbox();
  }

  displayPrice(price) {
    return `${price.toFixed(2)} €`.replace(/\./g,',')
  }

  displayPercent(percent) {
    return `${percent.toFixed(2)} %`.replace(/\./g,',')
  }

  updatePrices(price, taxPrice, discountPrice) {
    this.priceExcludingTaxTarget.textContent = this.displayPrice(price);
    this.taxPriceTarget.textContent = this.displayPrice(taxPrice);
    this.priceWithTaxTarget.textContent = this.displayPrice(price+taxPrice+discountPrice);
    this.discountPriceTarget.textContent = this.displayPrice(discountPrice);
  }

  computePrices() {
    let price = 0;
    let taxPrice = 0;
    let currentPrice = 0;
    let currentDiscountPrice = 0;
    let discountPrice = 0;
    let discount = parseFloat(this.discountTarget.value.replace(',', '.'))
    this.discountForTotalTarget.textContent = this.displayPercent(discount);
    $('#current_mission .quotation_mission').not('.no-discount').each( (index, element) => {
      currentPrice = $(element).find('.mission_price input').val().replace(',', '.')
      if (currentPrice === "") {
        currentPrice = 0;
      } else {
        currentPrice = parseFloat(currentPrice);
      }
      price += currentPrice;
      currentDiscountPrice = -0.01*currentPrice*discount;
      discountPrice += currentDiscountPrice
      taxPrice += (currentPrice+currentDiscountPrice) * parseFloat(this.vatRateTarget.value)
    })
    $('#current_mission .quotation_mission.no-discount').each( (index, element) => {
      currentPrice = $(element).find('.mission_price input').val().replace(',', '.')
      if (currentPrice === "") {
        currentPrice = 0;
      } else {
        currentPrice = parseFloat(currentPrice);
      }
      price += currentPrice;
      taxPrice += currentPrice * parseFloat(this.vatRateTarget.value.replace(',', '.'))
    })
    this.updatePrices(price, taxPrice, discountPrice);
  }

  removeMission(event) {
    let quotation_mission = $(event.target).parents('.quotation_mission')
    quotation_mission.hide();
    quotation_mission.find('.mission_price input').val(0);
    quotation_mission.find('input.destroy').val(1);
    this.computePrices();
    this.evalNoDiscount();
  }

  updateTerm(event) {
    let term = $(event.target).parents('.field');
    let new_value = term.find('.checked').length === 1 ? 0 : 1;
    term.find('input.destroy').val(new_value);
  }

  openModal(event, modalId) {
    $(modalId).modal({selector: {
      close: '.close',
      approve: '.actions .ok',
      deny: '.actions .cancel'
    }}).modal('show');
    $('.ui.checkbox').checkbox();
    event.preventDefault();
  }

  openInterventionTermsModal(event) {
    this.openModal(event, '#modal-quotation_intervention_terms');
  }

  openPricingTermsModal(event) {
    this.openModal(event, '#modal-quotation_pricing_terms');
  }

  addMissionSuccess(){
    let nb_mission = parseInt(this.nbMissionTarget.value);
    let new_html = $('#new_quotation_mission tbody').html().replace(/\[0\]/g, `[${nb_mission}]`).replace(/_0_/g, `_${nb_mission}_`);
    $('#current_mission').append(new_html);
    this.nbMissionTarget.value = nb_mission + 1
    this.computePrices();
    this.evalNoDiscount();
  }

  evalNoDiscount() {
    if($('.quotation_mission.no-discount:visible').length > 0){
      $('.no-discount-legend').show();
    } else {
      $('.no-discount-legend').hide();
    }
  }

}

