import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.setRejected();
    this.computePrices();
    this.displayVatRateTarget.textContent = this.displayPercent(100*parseFloat(this.vatRateTarget.value))
    this.evalNoDiscount();
  }

  displayPrice(price) {
    return `${price.toFixed(2)} €`.replace(/\./g,',')
  }

  displayPercent(percent) {
    return `${percent.toFixed(2)} %`.replace(/\./g,',')
  }

  updatePrices(price, taxPrice, discountPrice) {
    this.priceExcludingTaxTarget.textContent = this.displayPrice(price);
    this.taxPriceTarget.textContent = this.displayPrice(taxPrice);
    this.taxPrice2Target.textContent = this.displayPrice(taxPrice);
    this.priceWithTaxTarget.textContent = this.displayPrice(price+taxPrice+discountPrice);
    this.priceWithDiscountTarget.textContent = this.displayPrice(price+discountPrice);
    this.priceWithDiscount2Target.textContent = this.displayPrice(price+discountPrice);
  }

  computePrices() {
    let price = 0;
    let taxPrice = 0;
    let currentPrice = 0;
    let discountPrice = 0;
    let discount = parseFloat(this.discountTarget.value)
    $('.invoice_mission, .invoice-line').not('.rejected').not('.no-discount').each( (index, element) => {
      currentPrice = $(element).find('.total_price').text().replace(',', '.')
      if (isNaN(currentPrice)) {
        currentPrice = 0;
      } else {
        currentPrice = parseFloat(currentPrice);
      }
      price += currentPrice;
    })
    discountPrice = -0.01*price*discount;
    $('.invoice_mission.no-discount').not('.rejected').each( (index, element) => {
      currentPrice = $(element).find('.total_price').text().replace(',', '.')
      if (isNaN(currentPrice)) {
        currentPrice = 0;
      } else {
        currentPrice = parseFloat(currentPrice);
      }
      price += currentPrice;
    })
    taxPrice = (price+discountPrice) * parseFloat(this.vatRateTarget.value)
    this.updatePrices(price, taxPrice, discountPrice);
  }

  computeQuantityPrice(e) {
    let line = $(e.target).parents('tr');
    let value = line.find('.unit-price input').val().replace(',', '.');
    let quantity = 0;
    if (line.hasClass('invoice_mission')) {
      quantity = parseInt(line.find('.quantity').text());
    } else {
      quantity = line.find('.quantity input').val();
    }
    let totalPrice = value * quantity;
    line.find('.total_price').text(totalPrice);
    this.computePrices();
  }

  toggleDisable(e) {
    let input = $(e.target);
    let invoiceMission = input.parents('tr.invoice_mission');
    let toDestroy = invoiceMission.find('input[type="hidden"].destroy');
    let actionIcon = invoiceMission.find('td.action i.icon.square');
    invoiceMission.toggleClass('rejected');
    this.computePrices();
    invoiceMission.find('td.unit-price input').prop('readonly', function(i, v) { return !v; });
    toDestroy.val(!(toDestroy.val() == 'true'));
    actionIcon.toggleClass('minus plus');
  }

  setRejected() {
    $('.invoice_mission').each( (index, element) => {
      let invoiceMission = $(element);
      let rejected = invoiceMission.find('input[type="hidden"].rejected').val() == 'true';
      if (rejected) {
        let toDestroy = invoiceMission.find('input[type="hidden"].destroy');
        let actionIcon = invoiceMission.find('td.action i.icon');
        invoiceMission.toggleClass('rejected');
        invoiceMission.find('td.unit-price input').prop('readonly', function(i, v) { return !v; });
        toDestroy.val(!(toDestroy.val() == 'true'));
        actionIcon.toggleClass('minus plus');
      }
    })
  }

  hide(e) {
    let line = $(e.target).parents('tr')
    let toDestroy = line.find('input[type="hidden"].destroy');
    line.hide()
    line.addClass('rejected');
    toDestroy.val('true');
    this.computePrices();
  }

  evalNoDiscount() {
    if($('.invoice_mission.no-discount:visible').length > 0){
      $('.no-discount-legend').show();
    } else {
      $('.no-discount-legend').hide();
    }
  }
}

