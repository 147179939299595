import { Controller } from "stimulus"

export default class extends Controller {
  connect() { }

  addTerms(relation) {
    let html = '';
    let offset =$(`#current_${relation} .fields`).length
    $(`#selected_${relation} input:checked`).each( (index, element) => {
      $(element).attr('checked', 'checked');
      html += $(element).parents('.field').wrap('<p/>').parent().html();
    });
    $(`#new_${relation}`).html(html);
    $('.ui.checkbox').checkbox();
  }

  addPricingTerms(event) {
    this.addTerms('quotation_pricing_terms');
  }

  addInterventionTerms(event) {
    this.addTerms('quotation_intervention_terms');
  }
}

